import React from 'react';

// Components
import EnLayout from '../../../components/EnLayout';
import EnAboutBanner from '../../../components/EnAboutBanner';

// Images
import Hero from '../../../img/about_04_m_w.jpg';
import MobHero from '../../../img/about_04_m_h.jpg';
import Bolt from '../../../img/about_04_p_01.jpg';
import CautionOne from '../../../img/about_04_p_02.jpg';
import CautionTwo from '../../../img/cer_JQA.jpg';
import CautionThree from '../../../img/about_04_p_03.jpg';
import CertOne from '../../../img/cer_jp_3218691.jpg';
import CertTwo from '../../../img/cer_tw_M572440.jpg';
import CertThree from '../../../img/about_04_p_04.gif';
import CertFour from '../../../img/about_04_p_05.jpg';
import CertFive from '../../../img/about_04_p_06.jpg';
import MaterialReport from '../../../img/about_04_en.pdf';

// Language
import { getPackOf } from '../../../lang';
const LANG = getPackOf('en');

const AboutMaterial = () => {
  return (
    <EnLayout>
      <EnAboutBanner pageTitle={LANG.嚴選材料} subTitle={LANG.嚴選材料副標題} bgImg={Hero} mobileBgImg={MobHero} />
      <section id="case" className="py-5">
        <div className="container">
          <div className="d-flex flex-wrap">
            <div className="col-12 pb-3">
              <p className="h3">{LANG.支架選材}</p>
              {LANG.支架選材內文}
            </div>
            <div id="" className="col-12 pb-3">
              <hr className="my-4" />
            </div>
            <div id="" className="col-12 pb-3">
              <p className="h3">{LANG.鋼材材料性質比較}</p>
              <table className="table table-striped table-bordered text-wrap small">
                <thead>
                  <tr>
                    <th>{LANG.材料種類}</th>
                    <th>
                      {LANG.密度}
                      <br />
                      (g/cm3)
                    </th>
                    <th>
                      {LANG.抗拉強度}
                      <br />
                      (N/mm2)
                    </th>
                    <th>
                      {LANG.降伏強度}
                      <br />
                      (N/mm2)
                    </th>
                    <th>
                      {LANG.疲勞強度}
                      <br />
                      (N/mm2)
                    </th>
                    <th>
                      {LANG.伸長率}
                      <br />%
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {LANG.鋁}
                      <br />
                      5182-O
                    </td>
                    <td>2.7</td>
                    <td>270</td>
                    <td>130</td>
                    <td>110</td>
                    <td>26</td>
                  </tr>
                  <tr>
                    <td>
                      {LANG.鋁}
                      <br />
                      6061T6
                    </td>
                    <td>2.7</td>
                    <td>310</td>
                    <td>276</td>
                    <td>100</td>
                    <td>17</td>
                  </tr>
                  <tr>
                    <td>
                      {LANG.一般鋼板}
                      <br />
                      SPCC
                    </td>
                    <td>7.85</td>
                    <td>270</td>
                    <td>180</td>
                    <td>210</td>
                    <td>1.5</td>
                  </tr>
                  <tr>
                    <td>
                      {LANG.鎂鋁鋅鋼板}
                      <br />
                      SDCC
                    </td>
                    <td>7.85</td>
                    <td>270</td>
                    <td>205</td>
                    <td>210</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <td>
                      {LANG.高張力鎂鋁鋅鋼板}
                      <br />
                      {LANG.四百型}
                    </td>
                    <td>7.85</td>
                    <td>400</td>
                    <td>300</td>
                    <td>240</td>
                    <td>16</td>
                  </tr>
                  <tr>
                    <td>
                      {LANG.高張力鎂鋁鋅鋼}
                      <br />
                      {LANG.四百九型}
                    </td>
                    <td>7.85</td>
                    <td>400</td>
                    <td>365</td>
                    <td>---</td>
                    <td>16</td>
                  </tr>
                </tbody>
              </table>
              <ul>
                {[
                  { title: LANG.抗拉強度, def: LANG.抗拉強度定義 },
                  { title: LANG.降伏強度, def: LANG.降伏強度定義 },
                  { title: LANG.疲勞強度, def: LANG.疲勞強度定義 },
                ].map((e) => {
                  return (
                    <li key={e.title}>
                      <h5 className="m-0" style={{ color: 'rgba(74,74,74)' }}>
                        <strong>{e.title}</strong>
                      </h5>
                      <p>{e.def}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-12 pb-3"></div>
          </div>
        </div>
      </section>
      <section id="case" className="py-5 bg-img bg-light">
        <div className="container">
          <div className="d-flex flex-wrap">
            <div className="col-12 col-md-6 pb-3">
              <p className="h3">{LANG.螺絲}</p>
              {LANG.螺絲內文}
            </div>
            <div className="col-12 col-md-6 pb-3">
              <img src={Bolt} alt="" className="img-thumbnail" />
            </div>
            <div id="" className="col-12 pb-3">
              <hr className="my-4" />
            </div>
            <div id="" className="col-12 pb-3">
              <p className="h3">{LANG.注意事項}</p>
              {LANG.注意事項內文}
            </div>
            <div id="" className="col-12 pb-3">
              <p className="h3">{LANG.設計}</p>
              <p>{LANG.設計內文}</p>
              <p className="h3">{LANG.材質}</p>
              <p>{LANG.材質內文}</p>
            </div>
            <div id="" className="col-12 pb-3">
              <p className="h3">{LANG.JQA試驗成績書}</p>
            </div>
            {[CautionOne, CautionTwo, CautionThree].map((e, i) => {
              return (
                <div id="" className="col-4 col-md-2 pb-3" key={i}>
                  <img src={e} alt="" className="img-thumbnail" />
                </div>
              );
            })}
            <div id="" className="col-12 pb-3">
              <hr className="my-4" />
            </div>
            <div id="" className="col-12 pb-3">
              <p className="h3">{LANG.專利}</p>
              <p>{LANG.專利內文}</p>
            </div>
            <div id="" className="col-4 col-md-2  pb-3">
              <img src={CertOne} alt="" className="img-thumbnail" />
            </div>
            <div id="" className="col-4 col-md-2  pb-3">
              <img src={CertTwo} alt="" className="img-thumbnail" />
            </div>
            <div id="" className="col-4 col-md-2  pb-3">
              <img src={CertThree} alt="" className="img-thumbnail" />
            </div>
            <div id="" className="col-4 col-md-2  pb-3">
              <img src={CertFour} alt="" className="img-thumbnail" />
              <p>{LANG.一般鎖固}</p>
            </div>
            <div id="" className="col-4 col-md-2 pb-3">
              <img src={CertFive} alt="" className="img-thumbnail" />
              <p>{LANG.隔絕鎖固}</p>
            </div>
          </div>
        </div>
      </section>
    </EnLayout>
  );
};

export default AboutMaterial;
